<template>
  <div>
    <section>
      <b-modal :active="isComponentModalActive" @close="closeModal">
        <div class="modal-card" style="width: auto">
          <section class="modal-card-body">
            <div>
              <b-loading :is-full-page="false" v-model="loadingMessages" :can-cancel="true"></b-loading>
              <chat-window class="chat-window" :single-room="true" :rooms="rooms" :current-user-id="getUserId"
                :messages="messages" height="calc(70vh - 100px)" :loading-rooms="false" :rooms-loaded="true"
                :messages-loaded="messagesLoaded" :show-footer="false" :message-actions="[]"
                :menu-actions="menuActions" @menu-action-handler="menuActionHandler"
                :show-reaction-emojis="false" @open-file="openFile" @fetch-messages="fetchMessages" :text-messages="{
                  ROOMS_EMPTY: 'Sem tickets',
                  ROOM_EMPTY: 'Nenhum ticket selecionado',
                  NEW_MESSAGES: 'Novas mensagens',
                  MESSAGE_DELETED: 'Esta mensagem foi apagada',
                  MESSAGES_EMPTY: 'Nenhuma mensagem',
                  CONVERSATION_STARTED: 'A conversa começou em:',
                  TYPE_MESSAGE: 'Digite sua mensagem',
                  SEARCH: 'Procurar ticket',
                  IS_ONLINE: 'Está online',
                  LAST_SEEN: 'Última visualização ',
                  IS_TYPING: 'Está digitando...',
                }" />
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-pulled-right" type="button" @click="closeModal">Fechar</button>
          </footer>
        </div>
      </b-modal>
    </section>
  </div>
</template>

<script>
import 'vue-advanced-chat/dist/vue-advanced-chat.css'
import ChatWindow from 'vue-advanced-chat'
import { get, pureBlobGet, pureGet, getUrl } from '../../utils/api'
import mixin from '../../utils/mixins'
import { mapGetters } from 'vuex'
import { isAudioFile, isVideoFile, isImageFile } from '../../utils/util'
import generatePDF from '../pdfs/TicketsMessages'

export default {
  name: 'ModalTicketMessages',
  components: {
    ChatWindow
  },
  props: {
    isComponentModalActive: {
      type: Boolean,
      required: true
    },
    ticket: {
      type: Object,
      required: true
    }
  },
  mixins: [mixin],
  data () {
    return {
      messages: [],
      messagesLoaded: false,
      loadingMessages: true,
      rooms: [],
      messagesPage: 1,
      urlPhotoShowing: '',
      menuActions: [
        {
          name: 'export',
          title: 'Exportar para PDF'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['getUserId']),
    getContactDetails () {
      return `${this.ticket.contact.id} - ${this.ticket.contact.name}`
    }
  },
  created () {
    this.rooms = [this.adaptTicketToRoom(this.ticket)]
  },
  methods: {
    menuActionHandler ({ roomId, action }) {
      if (action.name === 'export') {
        generatePDF(this.messages, this.rooms[0])
      }
    },
    saveFile (blob, filename) {
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename)
      } else {
        const a = document.createElement('a')
        document.body.appendChild(a)
        const url = URL.createObjectURL(blob)
        a.href = url
        a.download = filename
        a.click()
        setTimeout(() => {
          URL.revokeObjectURL(url)
          document.body.removeChild(a)
        }, 0)
      }
    },
    async openFile ({ message, action }) {
      if (message.files[0].url.indexOf('message-details') > -1) {
        let messageDetails = await pureGet(message.files[0].url)
        messageDetails = messageDetails.data

        if (messageDetails.file_base64) {
          message.files[0].url = `data:${messageDetails.file_mimetype};base64,${messageDetails.file_base64}`
          message.files[0].type = messageDetails.file_mimetype
          if ((!isImageFile(message.files[0].name) && !isVideoFile(message.files[0].name) && !isAudioFile(message.files[0].name))) {
            const a = document.createElement('a')
            a.href = message.files[0].url
            let ext
            try {
              ext = message.files[0].type.split('/')[1]
            } catch (error) {
              ext = ''
            }
            a.setAttribute('target', '_blank')
            a.download = `${message.files[0].name ?? 'download.' + ext}`
            a.click()
          }
        } else if (message.files[0].url.indexOf('file') > -1) {
          await pureGet(message.files[0].url)
          let mediaDetails = await get(`tickets/message-details/${message.id}`)
          mediaDetails = mediaDetails.data

          message.files[0].url = getUrl(`tickets/file/${message.id}`)

          if (isImageFile(mediaDetails.file.mimetype) || isVideoFile(mediaDetails.file.mimetype) || isAudioFile(mediaDetails.file.mimetype)) {
            await this.adaptFileMessage(mediaDetails, message)
          } else {
            const messageDetails = await pureBlobGet(message.files[0].url)

            if (messageDetails.data) {
              const binaryData = [messageDetails.data]
              this.saveFile(new Blob(binaryData, { type: message.files[0].customtype }), message.files[0].name)
            }
          }
        }
      } else {
        const messageDetails = await pureBlobGet(message.files[0].url)

        if (messageDetails.data) {
          const binaryData = [messageDetails.data]
          this.saveFile(new Blob(binaryData, { type: message.files[0].customtype }), message.files[0].name)
        }
      }
    },
    async fetchMessages ({ room, options = {} }) {
      if (options.reset) {
        this.messages = []
        this.messagesPage = 1
      }
      this.messagesLoaded = false
      this.loadingMessages = true
      const r = await get(`reports/messages/${room.roomId}/${this.messagesPage}`)
      this.messages = [...r.data.reverse().map(message => this.adaptMessageToMessage(message)), ...this.messages]
      // if (!r.data.length || r.data.length < 15) { this.messagesLoaded = true }
      this.messagesLoaded = true
      this.messagesPage++

      this.loadingMessages = false
    },
    closeModal () {
      this.$emit('closeModal')
    },
    scrollMessageContainerToBottom () {
      const messageContainer = document.querySelector('.vac-container-scroll')
      // eslint-disable-next-line no-return-assign
      this.$nextTick(() => messageContainer.scrollTop = messageContainer.scrollHeight)
    },
    getRoomName (ticket) {
      if (ticket.contact) { return `${ticket.id} - ${ticket.contact.name}` }
      if (ticket.client_name) { return `${ticket.id} - ${ticket.client_name}` }

      return `${ticket.id} - ${ticket.client_phone}`
    },
    adaptTicketToRoom (ticket) {
      return {
        ...ticket,
        roomId: ticket.id,
        roomName: this.getRoomName(ticket),
        unreadCount: ticket.unread_count,
        lastMessage: {
          content: `Ticket: ${ticket.id}`,
          timestamp: `${this.formatShortDate(ticket.created_at)}`,
          date: ticket.created_at
        },
        users: [
          {
            _id: this.getUserId,
            username: 'John Doe'
          },
          {
            _id: ticket.client_phone,
            username: 'John Snow'
          }
        ],
        typingUsers: [],
        attendant_id: ticket.attendant_id
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.modal .animation-content .modal-card {
  overflow: visible !important;
}

.modal-card-body {
  overflow: visible !important;
}

.modal-card-foot {
  justify-content: flex-end;
}

.vac-room-item-open {
  border-color: aquamarine;
  border-width: 1px;
  border-style: solid;
  // animation: blinker 1.5s linear infinite;
}

.vac-container-scroll__margin-bottom {
  margin-bottom: 60px;
}

.vac-box-footer__hidden {
  display: none !important;
}

.select-attendant-div {
  background-color: aquamarine;
}

@keyframes blinker {
  50% {
    opacity: 30%;
  }
}
</style>
