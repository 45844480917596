<template>
  <div>
    <button
        class="button is-success is-outlined"
        :disabled="!ticket.is_open"
        expanded
        type="button"
        @click="openTransferTicketModal(ticket)"
    >
        Transferir
    </button>
    <ModalTransferTicket
        v-if="isModalSelectAttendantOpen"
        :attendants="getAttendants"
        :departments="allDepartmentsEnabled"
        v-on:attendantSelected="handleTransferAttendantSelected"
        v-on:closeModal="closeModal"
        :isComponentModalActive="isModalSelectAttendantOpen"
    />
  </div>
</template>

<script>
import mixin from '../../utils/mixins'
import ModalTransferTicket from '@/components/modals/ModalTransferTicket'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    ModalTransferTicket
  },
  mixins: [mixin],
  props: {
    ticket: {
      require: true,
      type: Object
    }
  },
  data () {
    return {
      isModalSelectAttendantOpen: false
    }
  },
  computed: {
    ...mapGetters([
      'allAttendantsEnabled',
      'allDepartmentsEnabled'
    ]),
    getAttendants () {
      return this.allAttendantsEnabled.filter(att => (att.status === 'online' || att.status === 'invisible'))
    },
    getDepartments () {
      return this.allDepartmentsEnabled.map((department) => {
        const c = department
        c.code = c.id
        c.name = `${c.name}`
        return c
      })
    }
  },
  methods: {
    ...mapActions([
      'fetchAttendants',
      'transferTicket',
      'fetchDepartments'
    ]),
    async openTransferTicketModal (ticket) {
      await this.fetchAttendants()
      await this.fetchDepartments(ticket.ww_phone)
      this.isModalSelectAttendantOpen = true
    },
    async handleTransferAttendantSelected (data) {
      this.closeModal()
      try {
        this.loading = true
        await this.transferTicket({
          ticket_id: this.ticket.id,
          department_id: data.department.id,
          attendant_id: data.attendant ? data.attendant.id : null,
          department_from: this.ticket.department_id
        })
        this.emitReloadTickets()
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: 'Erro ao transferir Ticket',
          message: `${error.message}`,
          type: 'is-danger',
          hasIcon: true,
          cancelText: 'Fechar'
        })
      } finally {
        this.loading = false
      }
    },
    emitReloadTickets (data) {
      this.$emit('reloadTickets', data)
    },
    closeModal () {
      this.isModalSelectAttendantOpen = false
    }
  }
}
</script>

<style scoped>
.w-100 {
  width: 100%;
}
</style>
