<template>
  <div>
    <button class="button is-info is-outlined" expanded type="button" @click="openMessagesTicketModal">
      Mensagens
    </button>
    <ModalTicketMessages v-if="isModalTicketDetailsOpen" :ticket="ticket" v-on:closeModal="closeModal"
      :isComponentModalActive="isModalTicketDetailsOpen" />
  </div>
</template>

<script>
import mixin from '../../utils/mixins'
import ModalTicketMessages from '@/components/modals/ModalTicketMessages'

export default {
  components: {
    ModalTicketMessages
  },
  mixins: [mixin],
  props: {
    ticket: {
      require: true,
      type: Object
    }
  },
  data () {
    return {
      selectedTicket: null,
      isModalTicketDetailsOpen: false
    }
  },
  methods: {
    async openMessagesTicketModal () {
      this.isModalTicketDetailsOpen = true
    },
    closeModal () {
      this.isModalTicketDetailsOpen = false
    }
  }
}
</script>

<style scoped>
.w-100 {
  width: 100%;
}
</style>
