<template>
  <div>
    <section>
      <b-modal :active="isComponentModalActive" @close="closeModal">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">Fechamento de ticket</p>
          </header>
          <b-loading v-if="loading" :is-full-page="false" :can-cancel="false" :active="true"></b-loading>
          <section class="modal-card-body">
            <b-field grouped>
              <b-field label="ID">
                <b-input :value="ticket.id" disabled></b-input>
              </b-field>
              <b-field label="Nome no whats" expanded>
                <b-input :value="ticket.client_name" disabled></b-input>
              </b-field>
              <b-field label="Contato" expanded>
                <b-input :value="ticket.contact ? `${ticket.contact.id} - ${ticket.contact.name}` : ''"
                  disabled></b-input>
              </b-field>
            </b-field>
            <b-field label="Motivo do fechamento*" v-if="reasons.length">
              <b-select placeholder="Selecione uma opção" v-model="reasonSelectedId" expanded>
                <option v-for="reason in getReasons" :key="reason.id" :value="reason.id">
                  {{ reason.title }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Descreva o motivo da finalização do ticket">
              <editor api-key="no-api-key" v-model="explanation" output-format="text" :init="{
                height: 200,
                menubar: false,
                plugins: [''],
                toolbar: '',
                branding: false,
                statusbar: false,
              }" />
            </b-field>
            <div class="field">
              <b-checkbox v-model="sendMessage" :disabled="(selectedReason && selectedReason.force_send_message)">Enviar
                mensagem ao cliente</b-checkbox>
            </div>
            <b-field label="Mensagem a ser enviada ao cliente">
              <editor api-key="no-api-key" v-model="message" output-format="text"
                :disabled="!sendMessage || (selectedReason ? !selectedReason.can_edit_message : false)" :init="{
                  height: 200,
                  menubar: false,
                  plugins: ['emoticons'],
                  toolbar: 'emoticons',
                  branding: false,
                  statusbar: false,
                }" />
            </b-field>
          </section>
          <footer class="modal-card-foot">
            <button class="button" type="button" @click="closeModal">
              Cancelar
            </button>
            <button class="button is-primary" @click="selectReason">
              Fechar ticket
            </button>
          </footer>
        </div>
      </b-modal>
    </section>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'

export default {
  name: 'ModalCloseTicket',
  props: {
    isComponentModalActive: {
      type: Boolean,
      required: true
    },
    reasons: {
      type: Array,
      required: true
    },
    ticket: {
      type: Object,
      required: true
    },
    settings: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true,
      default: true
    }
  },
  components: {
    Editor
  },
  watch: {
    sendMessage (send) {
      if (send) {
        this.configureInitialMessage()
      } else {
        this.message = ''
      }
    },
    reasonSelectedId (id) {
      this.configureInitialMessage()
    }
  },
  computed: {
    filteredDataObj () {
      return this.reasons.filter((option) => {
        return (
          option.title
            .toString()
            .toLowerCase()
            .indexOf(this.filter.toLowerCase()) >= 0
        )
      })
    },
    getReasons () {
      return this.reasons.sort(function (a, b) {
        if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return 1
        }
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return -1
        }
        // a must be equal to b
        return 0
      })
    }
  },
  mounted () {
    if (this.settings) {
      const setting = this.settings.find(set => set.phone === this.ticket.ww_phone || !this.ticket.ww_phone)
      this.configureInitialMessage(setting)
    }
  },
  data () {
    return {
      reasonSelectedId: null,
      selectedReason: null,
      filter: '',
      explanation: '',
      message: '',
      sendMessage: true
    }
  },
  methods: {
    configureInitialMessage (setting) {
      this.selectedReason = this.reasons.find((res) => res.id === this.reasonSelectedId)
      if (this.selectedReason) {
        this.message = this.selectedReason.custom_message ? this.selectedReason.custom_message : setting ? setting.message_end_ticket : ''
        if (this.selectedReason.force_send_message) {
          this.sendMessage = true
        }
      } else if (setting) {
        this.message = setting.message_end_ticket
      }
    },
    selectReason () {
      this.$buefy.dialog.confirm({
        title: 'Fechamento de ticket',
        message: `Deseja realmente fechar o ticket <b>${this.ticket.id}</b>?`,
        confirmText: 'Fechar',
        type: 'is-danger',
        hasIcon: true,
        cancelText: 'Cancelar',
        onConfirm: () => this.emitReasonSelected()
      })
    },
    emitReasonSelected () {
      this.$emit('closeTicket', {
        ticket: this.ticket,
        should_notify_client: this.sendMessage,
        ticket_reason_id: this.reasonSelectedId,
        ticket_reason_explanation: this.explanation,
        ticket_close_message: this.sendMessage ? this.message.replaceAll('<br>', '\n') : null,
        closed_at: new Date(Date.now())
      })
    },
    closeModal () {
      this.$emit('closeModal')
    }
  }
}
</script>

<style lang="scss" scoped>
.modal .animation-content .modal-card {
  overflow: visible !important;
}

.modal-card-body {
  overflow: visible !important;
}
</style>
