var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',[_c('b-modal',{attrs:{"active":_vm.isComponentModalActive},on:{"close":_vm.closeModal}},[_c('div',{staticClass:"modal-card",staticStyle:{"width":"auto"}},[_c('section',{staticClass:"modal-card-body"},[_c('div',[_c('b-loading',{attrs:{"is-full-page":false,"can-cancel":true},model:{value:(_vm.loadingMessages),callback:function ($$v) {_vm.loadingMessages=$$v},expression:"loadingMessages"}}),_c('chat-window',{staticClass:"chat-window",attrs:{"single-room":true,"rooms":_vm.rooms,"current-user-id":_vm.getUserId,"messages":_vm.messages,"height":"calc(70vh - 100px)","loading-rooms":false,"rooms-loaded":true,"messages-loaded":_vm.messagesLoaded,"show-footer":false,"message-actions":[],"menu-actions":_vm.menuActions,"show-reaction-emojis":false,"text-messages":{
                ROOMS_EMPTY: 'Sem tickets',
                ROOM_EMPTY: 'Nenhum ticket selecionado',
                NEW_MESSAGES: 'Novas mensagens',
                MESSAGE_DELETED: 'Esta mensagem foi apagada',
                MESSAGES_EMPTY: 'Nenhuma mensagem',
                CONVERSATION_STARTED: 'A conversa começou em:',
                TYPE_MESSAGE: 'Digite sua mensagem',
                SEARCH: 'Procurar ticket',
                IS_ONLINE: 'Está online',
                LAST_SEEN: 'Última visualização ',
                IS_TYPING: 'Está digitando...',
              }},on:{"menu-action-handler":_vm.menuActionHandler,"open-file":_vm.openFile,"fetch-messages":_vm.fetchMessages}})],1)]),_c('footer',{staticClass:"modal-card-foot"},[_c('button',{staticClass:"button is-pulled-right",attrs:{"type":"button"},on:{"click":_vm.closeModal}},[_vm._v("Fechar")])])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }