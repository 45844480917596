<template>
  <div>
    <button
      class="button is-danger is-outlined"
      :disabled="!ticket.is_open"
      expanded
      type="button"
      @click="openCloseTicketModal(ticket)"
    >
      Fechar
    </button>
    <ModalCloseTicket
      v-if="isModalCloseTicketOpen"
      :ticket="ticket"
      :reasons="allReasons"
      :settings="allSettings"
      v-on:closeModal="closeModal"
      v-on:closeTicket="closeTicketHandler"
      :isComponentModalActive="isModalCloseTicketOpen"
      :loading="fetchingSettings || fetchingReasons"
    />
  </div>
</template>

<script>
import mixin from '../../utils/mixins'
import { post } from '../../utils/api'
import ModalCloseTicket from '@/components/TicketsCloseReason/ModalCloseTicket'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    ModalCloseTicket
  },
  mixins: [mixin],
  props: {
    ticket: {
      require: true,
      type: Object
    }
  },
  data () {
    return {
      isModalCloseTicketOpen: false
    }
  },
  computed: {
    ...mapGetters([
      'allReasons',
      'allSettings',
      'fetchingReasons',
      'fetchingSettings'
    ])
  },
  methods: {
    ...mapActions([
      'fetchEnabledReasons',
      'fetchSettings'
    ]),
    async openCloseTicketModal () {
      await this.fetchEnabledReasons(this.ticket.ww_phone)
      await this.fetchSettings()

      this.isModalCloseTicketOpen = true
    },
    // eslint-disable-next-line camelcase
    async closeTicket ({ roomId, should_notify_client, ticket_reason_id, ticket_reason_explanation, ticket_close_message, closed_at }) {
      await post(`tickets/close/${roomId}`, {
        should_notify_client,
        ticket_reason_id,
        ticket_reason_explanation,
        ticket_close_message,
        closed_at
      })
    },
    closeTicketHandler (data) {
      this.closeTicket({
        roomId: data.ticket.id,
        ...data
      }).then(() => {
        this.closeModal()
        this.emitReloadTickets()
      })
        .catch(err => {
          this.$buefy.dialog.alert({
            title: 'Erro no fechamento do ticket',
            message: err.response.data.message,
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
        })
    },
    emitReloadTickets (data) {
      this.$emit('reloadTickets', data)
    },
    closeModal () {
      this.isModalCloseTicketOpen = false
    }
  }
}
</script>

<style scoped>
.w-100 {
  width: 100%;
}
</style>
