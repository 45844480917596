var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',[_c('b-modal',{attrs:{"active":_vm.isComponentModalActive},on:{"close":_vm.closeModal}},[_c('div',{staticClass:"modal-card",staticStyle:{"width":"auto"}},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v("Fechamento de ticket")])]),(_vm.loading)?_c('b-loading',{attrs:{"is-full-page":false,"can-cancel":false,"active":true}}):_vm._e(),_c('section',{staticClass:"modal-card-body"},[_c('b-field',{attrs:{"grouped":""}},[_c('b-field',{attrs:{"label":"ID"}},[_c('b-input',{attrs:{"value":_vm.ticket.id,"disabled":""}})],1),_c('b-field',{attrs:{"label":"Nome no whats","expanded":""}},[_c('b-input',{attrs:{"value":_vm.ticket.client_name,"disabled":""}})],1),_c('b-field',{attrs:{"label":"Contato","expanded":""}},[_c('b-input',{attrs:{"value":_vm.ticket.contact ? `${_vm.ticket.contact.id} - ${_vm.ticket.contact.name}` : '',"disabled":""}})],1)],1),(_vm.reasons.length)?_c('b-field',{attrs:{"label":"Motivo do fechamento*"}},[_c('b-select',{attrs:{"placeholder":"Selecione uma opção","expanded":""},model:{value:(_vm.reasonSelectedId),callback:function ($$v) {_vm.reasonSelectedId=$$v},expression:"reasonSelectedId"}},_vm._l((_vm.getReasons),function(reason){return _c('option',{key:reason.id,domProps:{"value":reason.id}},[_vm._v(" "+_vm._s(reason.title)+" ")])}),0)],1):_vm._e(),_c('b-field',{attrs:{"label":"Descreva o motivo da finalização do ticket"}},[_c('editor',{attrs:{"api-key":"no-api-key","output-format":"text","init":{
              height: 200,
              menubar: false,
              plugins: [''],
              toolbar: '',
              branding: false,
              statusbar: false,
            }},model:{value:(_vm.explanation),callback:function ($$v) {_vm.explanation=$$v},expression:"explanation"}})],1),_c('div',{staticClass:"field"},[_c('b-checkbox',{attrs:{"disabled":(_vm.selectedReason && _vm.selectedReason.force_send_message)},model:{value:(_vm.sendMessage),callback:function ($$v) {_vm.sendMessage=$$v},expression:"sendMessage"}},[_vm._v("Enviar mensagem ao cliente")])],1),_c('b-field',{attrs:{"label":"Mensagem a ser enviada ao cliente"}},[_c('editor',{attrs:{"api-key":"no-api-key","output-format":"text","disabled":!_vm.sendMessage || (_vm.selectedReason ? !_vm.selectedReason.can_edit_message : false),"init":{
                height: 200,
                menubar: false,
                plugins: ['emoticons'],
                toolbar: 'emoticons',
                branding: false,
                statusbar: false,
              }},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1)],1),_c('footer',{staticClass:"modal-card-foot"},[_c('button',{staticClass:"button",attrs:{"type":"button"},on:{"click":_vm.closeModal}},[_vm._v(" Cancelar ")]),_c('button',{staticClass:"button is-primary",on:{"click":_vm.selectReason}},[_vm._v(" Fechar ticket ")])])],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }