<template>
  <div>
    <section>
      <b-modal :active="isComponentModalActive" @close="closeModal" has-modal-card>
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">Selecionar departamento para transferência de ticket</p>
          </header>
          <section class="modal-card-body">
            <div class="row columns is-multiline is-vcentered">
              <div class="column">
                <b-field label="Departamentos disponíveis">
                  <b-autocomplete
                    v-model="filterDpt"
                    placeholder="Selecione um departamento"
                    :keep-first="false"
                    :open-on-focus="true"
                    :data="filteredDptObj"
                    field="name"
                    @select="option => (departmentSelected = option)"
                    :clearable="true"
                  />
                </b-field>
              </div>
              <div class="column">
                <b-field label="Atendentes disponíveis">
                  <b-autocomplete
                    :disabled="!departmentSelected"
                    v-model="filterAtt"
                    placeholder="Selecione um atendente"
                    :keep-first="false"
                    :open-on-focus="true"
                    :data="filteredDataObj"
                    field="name"
                    @select="option => (attendantSelected = option)"
                    :clearable="true"
                  >
                  <template #empty>Não foram encontrados atendentes online</template>
                  </b-autocomplete>
                </b-field>
              </div>
            </div>
            <b-field label="Nota sobre a transferência">
              <editor api-key="no-api-key" v-model="note" output-format="text"
                :init="{
                  height: 200,
                  menubar: false,
                  plugins: ['emoticons'],
                  toolbar: 'emoticons',
                  branding: false,
                  statusbar: false,
                }"
              />
            </b-field>
          </section>
          <footer class="modal-card-foot">
            <button class="button" type="button" @click="closeModal">Fechar</button>
            <button class="button is-primary" @click="selectAttendant">Selecionar</button>
          </footer>
        </div>
      </b-modal>
    </section>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'

export default {
  name: 'ModalTransferTicket',
  props: {
    isComponentModalActive: {
      type: Boolean,
      required: true
    },
    departments: {
      type: Array,
      required: true
    },
    attendants: {
      type: Array,
      required: true
    }
  },
  components: {
    Editor
  },
  computed: {
    filteredDataObj () {
      return this.departmentAttendants.filter(option => {
        return (
          option.name
            .toString()
            .toLowerCase()
            .indexOf(this.filterAtt.toLowerCase()) >= 0 &&
          option.status === 'online'
        )
      })
    },
    filteredDptObj () {
      return this.departments.filter(option => {
        return (
          option.name
            .toString()
            .toLowerCase()
            .indexOf(this.filterDpt.toLowerCase()) >= 0
        )
      })
    }
  },
  data () {
    return {
      attendantSelected: null,
      departmentAttendants: [],
      departmentSelected: null,
      filterAtt: '',
      filterDpt: '',
      note: ''
    }
  },
  watch: {
    departmentSelected (newValue) {
      if (newValue) {
        this.departmentAttendants = this.attendants.filter(att => {
          if (att.departments.find(dpt => dpt.id === newValue.id) || att.supervisor_all_departments) {
            return att
          }
        })
      } else {
        this.departmentAttendants = []
      }
      this.selectGroupColor()
    }
  },
  methods: {
    selectAttendant () {
      if (!this.departmentSelected) {
        this.$buefy.dialog.alert({
          title: 'Departamento não selecionado',
          message: 'É necessário selecionar um <b>departamento</b> caso queira prosseguir!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })

        return
      }
      let message = `Deseja realmente transferir o ticket para o departamento <b>${this.departmentSelected.name}</b>?`
      if (this.attendantSelected) {
        message = `Deseja realmente transferir o ticket para o atendente <b>${this.attendantSelected.name}</b> do departamento <b>${this.departmentSelected.name}</b>?`
      }

      this.$buefy.dialog.confirm({
        title: 'Transferência de ticket',
        message: message,
        confirmText: 'Transferir',
        type: 'is-danger',
        hasIcon: true,
        cancelText: 'Cancelar',
        onConfirm: () => this.emitAttendantSelected({ department: this.departmentSelected, attendant: this.attendantSelected, note: this.note })
      })
    },
    emitAttendantSelected (data) {
      this.$emit('attendantSelected', data)
    },
    closeModal () {
      this.$emit('closeModal')
    },
    selectGroupColor () {
      const groups = this.$el.querySelectorAll('.attendants-autocomplete-status>.autocomplete>.dropdown-menu>.dropdown-content>div.dropdown-item')
      groups.forEach(el => {
        if (el.textContent.replaceAll(' ', '') === 'Online') {
          el.setAttribute('style', 'color: green')
        }
        if (el.textContent.replaceAll(' ', '') === 'Ausentes') {
          el.setAttribute('style', 'color: orange')
        }
      })
    }
  },
  created () {
    this.$socket.on('attendant_status_changed', ({status, attendant_id}) => {
      this.departmentAttendants = this.departmentAttendants.map(att => {
        if (att.id === attendant_id) {
          att.status = status
        }
        return att
      })
    })
  },
  beforeDestroy () {
    this.$socket.removeAllListeners('attendant_status_changed')
  }
}
</script>

<style lang="scss" scoped>
  .modal .animation-content .modal-card {
    overflow: visible !important;
  }

  .modal-card-body {
    overflow: visible !important;
  }
</style>
