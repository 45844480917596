var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',[_c('b-modal',{attrs:{"active":_vm.isComponentModalActive,"has-modal-card":""},on:{"close":_vm.closeModal}},[_c('div',{staticClass:"modal-card",staticStyle:{"width":"auto"}},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v("Selecionar departamento para transferência de ticket")])]),_c('section',{staticClass:"modal-card-body"},[_c('div',{staticClass:"row columns is-multiline is-vcentered"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Departamentos disponíveis"}},[_c('b-autocomplete',{attrs:{"placeholder":"Selecione um departamento","keep-first":false,"open-on-focus":true,"data":_vm.filteredDptObj,"field":"name","clearable":true},on:{"select":option => (_vm.departmentSelected = option)},model:{value:(_vm.filterDpt),callback:function ($$v) {_vm.filterDpt=$$v},expression:"filterDpt"}})],1)],1),_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Atendentes disponíveis"}},[_c('b-autocomplete',{attrs:{"disabled":!_vm.departmentSelected,"placeholder":"Selecione um atendente","keep-first":false,"open-on-focus":true,"data":_vm.filteredDataObj,"field":"name","clearable":true},on:{"select":option => (_vm.attendantSelected = option)},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_vm._v("Não foram encontrados atendentes online")]},proxy:true}]),model:{value:(_vm.filterAtt),callback:function ($$v) {_vm.filterAtt=$$v},expression:"filterAtt"}})],1)],1)]),_c('b-field',{attrs:{"label":"Nota sobre a transferência"}},[_c('editor',{attrs:{"api-key":"no-api-key","output-format":"text","init":{
                height: 200,
                menubar: false,
                plugins: ['emoticons'],
                toolbar: 'emoticons',
                branding: false,
                statusbar: false,
              }},model:{value:(_vm.note),callback:function ($$v) {_vm.note=$$v},expression:"note"}})],1)],1),_c('footer',{staticClass:"modal-card-foot"},[_c('button',{staticClass:"button",attrs:{"type":"button"},on:{"click":_vm.closeModal}},[_vm._v("Fechar")]),_c('button',{staticClass:"button is-primary",on:{"click":_vm.selectAttendant}},[_vm._v("Selecionar")])])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }